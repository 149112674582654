<template>
  <div class="lgc__form-w">
    <div class="lgc__type">
      <div class="type__block">
        <label class="radio-container">
          <input
            v-model="isAdmLogin"
            :class="['user', !isAdmLogin ? '--active' : '']"
            :value="false"
            type="radio"
            :checked="!isAdmLogin"
          />
          <span class="radio-checkmark"></span>
          Usuário
        </label>
      </div>
      <div class="type__block">
        <label class="radio-container">
          <input
            v-model="isAdmLogin"
            :class="['user', isAdmLogin ? '--active' : '']"
            :value="true"
            type="radio"
            :checked="isAdmLogin"
          />
          <span class="radio-checkmark"></span>
          Administrador
        </label>
      </div>
    </div>
    <div class="lgc__form-c">
      <ValidationMessage v-show="loginState === false">{{ messageError }}</ValidationMessage>

      <InputText
        v-if="!isAdmLogin"
        :vmodel.sync="company"
        field-label="Organização"
        field-placeholder="Digite sua organização"
        :state="loginState"
      />

      <InputText
        v-if="!isAdmLogin"
        :vmodel.sync="userName"
        field-label="Usuário"
        field-placeholder="Digite seu usuario"
        :state="loginState"
      />
      <InputText
        v-if="isAdmLogin"
        :vmodel.sync="email"
        field-label="E-mail"
        field-placeholder="Digite seu e-mail"
        :state="loginState"
      />

      <InputText
        input-i-d="password-input"
        :vmodel.sync="password"
        field-label="Senha"
        field-placeholder="Digite sua senha"
        :state="loginState"
        input="password"
      />

      <div class="lgc__checkbox-c">
        <Checkbox
          checkbox-text="Lembrar-me"
          :checkbox-value.sync="checkboxValue"
        />
        <span
          class="lgc__recover-password"
          @click="redirectToForgotPage"
          >Esqueceu sua senha?</span
        >
      </div>
    </div>

    <ButtonFullWidth
      class="btn-enter"
      :disabled="disabledButton"
      @click="login"
    >
      <div slot="text">Entrar</div>
    </ButtonFullWidth>
  </div>
</template>

<script>
  import ValidationMessage from '@/views/authentication/components/card-layout/components/validation-message';
  import InputText from '@/components/input-text';
  import ButtonFullWidth from '@/components/buttons/button-full-width';
  import Checkbox from '@/components/checkbox';
  import useJwt from '@/auth/mainJwt';
  import { BFormRadio } from 'bootstrap-vue';

  import { mapActions } from 'vuex';

  export default {
    name: 'LoginForm',
    components: {
      ValidationMessage,
      InputText,
      ButtonFullWidth,
      Checkbox,
      BFormRadio
    },
    data: () => ({
      prefix: '',
      company: '',
      userName: '',
      email: '',
      password: '',
      temporaryToken: '',
      siteGuid: '',
      isAdmLogin: false,
      loginState: null,
      checkboxValue: false,
      disabledButton: false,
      messageError: 'Credenciais inválidas'
    }),
    mounted() {
      this.blockCopyAndPasteInPasswordInput();
      this.setEventListenerKeydown();
    },
    methods: {
      ...mapActions('userStore', ['setUser']),
      blockCopyAndPasteInPasswordInput() {
        const input = document.querySelector('#password-input');

        input.addEventListener('paste', function (e) {
          e.preventDefault();
        });

        input.addEventListener('copy', function (e) {
          e.preventDefault();
        });
      },
      clearFields() {
        this.company = '';
        this.userName = '';
        this.email = '';
        this.password = '';
      },
      validateFields() {
        const { company, userName, email, password } = this;
        const validate = !company || !userName || !password ? false : null;
        const validateADM = !email || !password || !this._validateEmail(email) ? false : null;

        const typeValidate = this.isAdmLogin ? validateADM : validate;
        this.loginState = typeValidate;
        return typeValidate === null;
      },
      async login() {
        const validation = this.validateFields();
        if (!validation) {
          this.messageError = 'Credenciais inválidas';
          return;
        }

        let prefix = !this.isAdmLogin ? this.company.toLowerCase() : null;

        this.disabledButton = true;
        this._showLoading();

        const payload = this.isAdmLogin
          ? {
              email: this.email,
              password: this.password
            }
          : {
              company: this.company,
              username: this.userName,
              password: this.password
            };

        let keepLogin = true;

        await this.$http2
          .post(`api/login${this.isAdmLogin ? '/administrator' : ''}`, payload)
          .then((response) => {
            this.temporaryToken = response.data.data.token;
            this.siteGuid = response.data.data.siteGuid[0];
            if (response.data.data.company) {
              prefix = response.data.data.company;
            }
          })
          .catch((error) => {
            const userNotAdmId = 103;
            if (error.response.data.errors) {
              error.response.data.errors.map((e) => {
                if (e.id === userNotAdmId) {
                  this.messageError =
                    'Você não possui este acesso. Por favor, entre em contato com o seu administrador';
                }
              });
            }
            keepLogin = false;
            this.disabledButton = false;
            this.loginState = false;
            this._hideLoading();
          });

        if (keepLogin) {
          await useJwt
            .login({
              email: this.isAdmLogin ? this.email : this.userName,
              password: this.password,
              prefix: prefix,
              remember: this.checkboxValue
            })
            .then((response) => {
              const loginData = response.data;
              const userData = response.data.userData;
              const infoAccount = {
                guid: userData.guid,
                employee_id: userData.employee_id,
                first_name: userData.first_name,
                last_name: userData.last_name,
                job_role: userData.job_role,
                photo: userData.photo
              };

              this.setUser({
                ...userData,
                name: `${infoAccount.first_name} ${infoAccount.last_name}`,
                role: infoAccount.job_role,
                picture: infoAccount.photo
              });

              const domain = window.location.href.includes('indsiders.com');
              const multipleAccount = this.$route.name === 'multiple-account';

              localStorage.setItem('accessToken', response.data.accessToken);
              localStorage.setItem('userData', JSON.stringify(userData));
              localStorage.setItem('prefix', prefix);

              if (
                localStorage.getItem('loggedAccountsInfo') &&
                !JSON.parse(localStorage.getItem('loggedAccountsInfo')).find(
                  (item) => item.guid == response.data.userData.guid
                )
              ) {
                let guidAtual = JSON.parse(localStorage.getItem('loggedAccountsInfo'));
                guidAtual.unshift(infoAccount);
                localStorage.setItem('loggedAccountsInfo', JSON.stringify(guidAtual));
              }

              !localStorage.getItem('loggedAccountsInfo') &&
                localStorage.setItem('loggedAccountsInfo', JSON.stringify([infoAccount]));

              this.$cookies.set(
                'userInfo',
                {
                  ...loginData,
                  currentSite: this.siteGuid,
                  temporaryToken: this.temporaryToken,
                  language: 'pt-BR'
                },
                '7D',
                null,
                domain ? 'indsiders.com' : null
              );

              if (multipleAccount) {
                window.location.href = this.$mySfcLink;
              } else {
                this.$router.push({ name: 'home' }).catch(() => {});
              }
              this.disabledButton = false;
              !multipleAccount && this._hideLoading();
            })
            .catch(() => {
              this.disabledButton = false;
              this.loginState = false;
              this._hideLoading();
            });
        }
      },
      redirectToForgotPage() {
        this.$router.push({ name: 'forgot' }).catch(() => {});
      },
      setEventListenerKeydown() {
        window.addEventListener('keydown', this.loginPerKeydown);
      },
      removeEventListenerKeydown() {
        window.removeEventListener('keydown', this.loginPerKeydown);
      },
      loginPerKeydown({ key }) {
        key === 'Enter' && this.login();
      }
    },
    watch: {
      isAdmLogin() {
        this.loginState = null;
        this.messageError = 'Credenciais inválidas';
        this.clearFields();
      }
    },
    destroyed() {
      this.removeEventListenerKeydown();
      this._hideLoading();
    }
  };
</script>

<style lang="scss" scoped>
  .lgc__form-w {
    width: 100%;
    border-top: 1px solid #eeeeee;

    .lgc__type {
      display: flex;
      gap: 16px;

      .type__block {
        width: 100%;
        display: flex;
        gap: 8px;
        align-items: center;
        justify-content: flex-start;
        border-radius: 6px;
        border: 1px solid #cfc4be;

        .radio-container {
          cursor: pointer;
          display: flex;
          align-items: center;
          color: #4c4541;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          margin-bottom: 0;
          width: 100%;
          height: 100%;
          padding: 15px 16px;

          input[type='radio'] {
            display: none;

            &:checked + .radio-checkmark {
              background-color: #fff;
              border-color: #974900;
            }

            &:checked + .radio-checkmark:after {
              display: block;
            }
          }

          .radio-checkmark {
            width: 18px;
            height: 18px;
            background-color: #fff;
            border-radius: 50%;
            position: relative;
            display: inline-block;
            margin-right: 10px;
            border: 1px solid #cfc4be;

            &:after {
              content: '';
              position: absolute;
              display: none;
              left: 2px;
              top: 2px;
              width: 12px;
              height: 12px;
              border: solid #974900;
              border-radius: 50%;
              background-color: #974900;
            }
          }
        }

        &:has(.--active) {
          border: 1px solid #974900;
        }
      }
    }

    .lgc__form-c {
      gap: 16px;
      margin: 16px 0 32px;

      display: flex;
      flex-direction: column;

      .lgc__checkbox-c {
        display: flex;
        justify-content: space-between;

        .lgc__recover-password {
          padding: 0px 14px;
          color: #974900;
          font-size: 12px;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: 0.15px;
          user-select: none;
          cursor: pointer;
        }
      }
    }
  }

  @media (max-width: 485px) {
    .lgc__form-w {
      .lgc__type {
        flex-direction: column;
      }
      .lgc__form-c {
        .lgc__checkbox-c {
          .lgc__recover-password {
            padding: 4px 14px !important;
            font-size: 12px !important;
            line-height: 20px !important;
          }
        }
      }
    }
  }
</style>
