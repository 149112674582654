<template>
  <div
    v-if="variant === 'full'"
    :key="`language-selector-${key}`"
    class="language-selector-full"
  >
    <Item
      dropdown
      :name="$t(selectedLanguage.title) ?? ''"
      :variant="variant"
      :open.sync="open"
    >
      <template #icon>
        <component :is="selectedLanguage.icon" />
      </template>
    </Item>
    <div
      v-if="open"
      class="selectable-languages"
    >
      <Item
        v-for="language in selectableLanguages"
        :id="language.id"
        :key="language.id"
        :name="$t(language.title)"
        :current-language="selectedLanguage.id"
        :variant="variant"
        @click="() => changeLanguage(language.slug)"
      >
        <template #icon>
          <component :is="language.icon" />
        </template>
      </Item>
    </div>
  </div>

  <div
    v-else
    :key="`language-selector-${key}`"
  >
    <div
      id="language_selector_short"
      class="language-selector-short"
      tabindex="0"
    >
      <Item
        :id="title.toLowerCase()"
        dropdown
        :name="$t(selectedLanguage.title) ?? ''"
        :variant="variant"
        :open.sync="open"
      >
        <template #icon>
          <component :is="selectedLanguage.icon" />
        </template>
      </Item>
    </div>
    <Popover
      :items="languagesList"
      target="language_selector_short"
      :title="$t(title)"
      placement="rightbottom"
      custom-position="sidebar"
      :has-language="false"
      triggers="focus"
    />
    <Tooltip
      :text="$t(title)"
      :target="title.toLowerCase()"
    />
  </div>
</template>

<script>
  import Item from '@/components/Menu/LanguageSelector/LanguageSelectorItem.vue';
  import Popover from '@/components/Menu/Popover.vue';
  import Tooltip from '@/components/Menu/Tooltip.vue';

  import BrazilFlag from '@/assets/icons/brazil-flag.svg';
  import USAFlag from '@/assets/icons/usa-flag.svg';

  const ICONS = { BrazilFlag, USAFlag };

  export default {
    name: 'LanguageSelector',
    components: { Item, Popover, Tooltip },
    props: {
      variant: {
        type: String,
        required: true
      }
    },
    data() {
      var self = this;
      return {
        key: 1,
        open: false,
        current: self.$i18n.locale,
        title: 'Menu.Languages.Title',
        ICONS
      };
    },
    computed: {
      languagesList() {
        return [
          {
            id: 1,
            slug: 'pt-BR',
            title: 'Menu.Languages.Portuguese',
            icon: this.ICONS.BrazilFlag,
            click: () => this.changeLanguage('pt-BR'),
            selected: this.$i18n.locale === 'pt-BR'
          },
          {
            id: 2,
            slug: 'en-US',
            title: 'Menu.Languages.English',
            icon: this.ICONS.USAFlag,
            click: () => this.changeLanguage('en-US'),
            selected: this.$i18n.locale === 'en-US'
          }
        ];
      },
      selectableLanguages() {
        return this.languagesList.slice().sort((a, b) => a.title.localeCompare(b.title));
      },
      selectedLanguage() {
        return this.languagesList.find((item) => item.slug === this.current);
      }
    },
    methods: {
      toggleSelector() {
        this.open = !this.open;
      },
      changeLanguage(slug) {
        this.$i18n.locale = slug;
        this.current = slug;
        const userInfo = this.$cookies.get('userInfo');
        if (userInfo) {
          const domain = window.location.href.includes('indsiders.com');
          this.$cookies.set(
            'userInfo',
            { ...userInfo, language: slug },
            '7D',
            null,
            domain ? 'indsiders.com' : null
          );
        }
        this.open = false;
        this.$emit('closePopover', this.open);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .selectable-languages,
  .language-selector-full {
    display: flex;
    flex-direction: column;
  }
  .language-selector-full {
    position: relative;
  }

  .selectable-languages {
    position: absolute;
    padding: 8px 0;
    top: 52px;
    width: 232px;
    background-color: #1a1c19;
    border-radius: 6px;
  }

  .language-selector-short {
    min-width: 48px;
  }
</style>
